window.Popbox = function () {
  let
    current = null
  const
    template = function (options) {
      const attributes = Object.keys(options).reduce(function (acc, key) {
        return `${acc} ${key}="${options[key]}" `
      }, '')

      return `
        <dialog-modal ${attributes}>
          <dialog-content>
            <dialog-header></dialog-header>
            <dialog-body></dialog-body>
            <dialog-actions></dialog-actions>
          </dialog-content>
        </dialog-modal>
      `
    },
    setTitle = function (optionsOrTitle) {
      let title
      if (typeof optionsOrTitle === 'object' && optionsOrTitle.hasOwnProperty('title')) {
        title = optionsOrTitle.title
      } else if (typeof optionsOrTitle === 'string') {
        title = optionsOrTitle
      }
      if (title) {
        let header = current.querySelector('dialog-header')
        let node = document.createElement('dialog-title')
        node.innerHTML = title
        header.appendChild(node)
      }
    },
    setContent = function (content) {
      let node = current.querySelector('dialog-body')
      if (typeof content.hasChildNodes === 'function') {
        node.innerHTML = ''
        node.appendChild(content)
      } else if (typeof content === 'string') {
        node.innerHTML = content
      }
    },
    appendToBody = function (options) {
      document.body.insertAdjacentHTML('beforeend', template(options))
      return document.body.lastElementChild
    },
    launchScripts = function () {
      if (current.hasAttribute('runscripts')) {
        current.querySelectorAll('script').forEach(scrpt => {
          eval(scrpt.textContent)
        })
      }
    },
    show = function () {
      current.open()
    },
    hide = function () {
      current.setAttribute('closing', '')
    },
    destroy = function () {
      current.setAttribute('die', '')
    },
    create = function (content, options = {}) {
      if (!content  && !options.hasOwnProperty('src') && !options.hasOwnProperty('encodedsrc')) {
        return console.error('Popbox dialog content required')
      } else {
        current = appendToBody(options)
        setTitle(options)
        setContent(content)
        launchScripts()
        show()

        return {
          setTitle,
          setContent,
          show,
          hide,
          destroy,
          getNode: function (selector) { return selector ? current.querySelector(selector) : current },
          getNodes: function (selector) { return current.querySelectorAll(selector) }
        }
      }
    }

  return { create }
}

window.Dialog = class {
  constructor (...args) {
    return Popbox().create(...args).getNode()
  }
}
